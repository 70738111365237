exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-case-studies-index-js": () => import("./../../../src/pages/case-studies/index.js" /* webpackChunkName: "component---src-pages-case-studies-index-js" */),
  "component---src-pages-redirect-js": () => import("./../../../src/pages/redirect.js" /* webpackChunkName: "component---src-pages-redirect-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-post-js-content-file-path-src-pages-blog-building-teamspectives-pulse-survey-data-model-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/src/pages/blog/building-teamspectives-pulse-survey-data-model.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-pages-blog-building-teamspectives-pulse-survey-data-model-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-pages-blog-employee-churn-emploee-turnover-costs-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/src/pages/blog/employee-churn-emploee-turnover-costs.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-pages-blog-employee-churn-emploee-turnover-costs-mdx" */),
  "component---src-templates-book-page-js": () => import("./../../../src/templates/book-page.js" /* webpackChunkName: "component---src-templates-book-page-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-community-page-js": () => import("./../../../src/templates/community-page.js" /* webpackChunkName: "component---src-templates-community-page-js" */),
  "component---src-templates-document-page-js": () => import("./../../../src/templates/document-page.js" /* webpackChunkName: "component---src-templates-document-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-pricing-page-js": () => import("./../../../src/templates/pricing-page.js" /* webpackChunkName: "component---src-templates-pricing-page-js" */),
  "component---src-templates-solution-page-js": () => import("./../../../src/templates/solution-page.js" /* webpackChunkName: "component---src-templates-solution-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

